import React, { useEffect, useState } from "react";
import { Box, Button, Stack, TextField } from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MenuItem from "@mui/material/MenuItem";

import { VkToggle } from "./VkToggle";

export const RateEdit = (props) => {
  // const EditRate = (props) => {
  const {
    sqldata,
    role,
    kantor,
    currency,
    crntknt,
    fclose,
    fsubmit,
    ...other
  } = props;

  // edited data
  const [base, setBase] = useState(crntknt); // base knt for rate
  const [edknt, setEdknt] = useState(() => [crntknt]);
  const [edbulk, setEdbulk] = useState(false);
  const [edcur, setEdcur] = useState("840");
  const [edqty, setEdqty] = useState("1");
  const [edbid, setEdbid] = useState("");
  const [edask, setEdask] = useState("");

  //   const foreignCurency = () => {
  //     if (currency === undefined) {
  //       return [];
  //     }
  //     return currency.filter((v) => v.id !== "" && v.dmst !== "1");
  //   };

  const editList = () => {
    if (role === "kant") return [{ id: crntknt, name: crntknt }];
    return kantor;
  };

  useEffect(() => {
    let rate = sqldata.find(
      (v) =>
        v.shop === base &&
        v.atclcode === edcur &&
        v.prc === (edbulk ? "bulk" : "")
    );
    // console.log(rate);
    if (rate !== undefined) {
      setEdqty(rate.cqty);
      setEdbid(rate.bid);
      setEdask(rate.ask);
    } else {
      setEdqty("1");
      setEdbid("");
      setEdask("");
    }
    return () => {};
  }, [base, edcur, edbulk]);

  const onSubmit = async (e) => {
    // console.log("onSubmit");
    e.preventDefault();
    fsubmit({
      reqid: "upd",
      rates: edknt.map((v) => {
        return {
          shop: v,
          atclcode: edcur,
          pricecode: edbulk ? "bulk" : "",
          qty: edqty,
          bid: edbid,
          ask: edask,
          tm: new Date().toISOString(),
        };
      }),
    });
  };

  return (
    <Box sx={{ maxWidth: { md: 360 } }} {...other}>
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
      <form onSubmit={onSubmit}>
        <Stack gap={1}>
          <Stack direction={"row"} gap={1} flexWrap="wrap">
            <VkToggle
              data={kantor}
              dflt={base}
              label="База"
              limit={0}
              allowAll={false}
              fcb={(v) => setBase(v)}
            />
            <FormControl sx={{ minWidth: 130 }} size="small">
              <ToggleButtonGroup
                id="fld-shop-tgl"
                label="Для"
                value={edknt}
                onChange={(e, v) => (v.length ? setEdknt(v) : "")}
                aria-label="knt toggle"
                size="small"
              >
                {editList().map((v) => {
                  return (
                    <ToggleButton
                      id={`tglknt_${v.id}`}
                      key={`tglknt_${v.id}`}
                      value={v.id}
                      aria-label={v.id}
                    >
                      {v.name}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </FormControl>
          </Stack>

          <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
            <VkToggle
              data={currency}
              dflt={edcur}
              allowAll={false}
              label="Валюта"
              fcb={(v) => setEdcur(v)}
            />

            <FormControlLabel
              control={<Switch />}
              size="small"
              label="ГУРТ"
              value={edbulk}
              onChange={(e) => setEdbulk(e.target.checked)}
            />
          </Stack>

          <Stack direction={"row"} gap={1}>
            <TextField
              label="Купівля"
              id="fld-bid"
              size="small"
              value={edbid}
              onChange={(e) => setEdbid(e.target.value)}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                type: "number",
                step: 0.001,
                // step: Number(getValues("fld-bid")) < 10 ? 0.001 : 0.01,
                // inputMode: "decimal",
              }}
            />
            <TextField
              label="Продаж"
              id="fld-ask"
              size="small"
              value={edask}
              onChange={(e) => setEdask(e.target.value)}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                type: "number",
                step: 0.001,
                // step: Number(getValues("fld-ask")) < 10 ? 0.001 : 0.01,
                // inputMode: "decimal",
              }}
            />
          </Stack>
          <Stack direction={"row"} gap={1} justifyContent={"flex-end"}>
            <Button
              // type="reject"
              variant="outlined"
              startIcon={<CloseIcon />}
              // color={grey[200]}
              onClick={() => fclose()}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              disabled={!(edknt.length && edcur !== "")}
              startIcon={<CheckIcon />}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
};
